export default [
  "😀", "😃", "😄", "😁", "😆", "😅", "😂", "🤣", "😊", "😇", "🙂", "🙃", "😉", "😌", "😍", "🥰", "😘", "😗", "😙", "😚",
  "😜", "😝", "😛", "🤑", "🤗", "🤭", "🤫", "🤔", "🤐", "😏", "😒", "🙄", "😬", "🤥", "😌", "😔",
  "😠", "😡", "🤬", "😤", "😾", "👿",
  "😢", "😭", "😞", "😖", "😟", "😫", "😩", "😰", "😨", "😱", "😧",
  "😲", "😮", "😯", "😳", "🥵", "🥶",
  "💖", "💕", "💞", "💘", "💝", "💗", "💓", "💙", "💚", "💛", "🧡",
  "👍", "👎", "✌️", "🤞", "🤟", "🤘", "👌", "🤙", "👏", "🙌", "👐", "🤲",
  "🐶", "🐱", "🐭", "🐹", "🐰", "🦊", "🐻", "🐼", "🐨", "🐯", "🦁", "🐮", "🐷",
  "🍏", "🍎", "🍐", "🍊", "🍋", "🍌", "🍉", "🍇", "🍓", "🍒", "🍑", "🥭", "🍍", "🥝", "🥑", "🍔", "🍕", "🍟", "🌭", "🍿",
  "⚽", "🏀", "🏈", "⚾", "🎾", "🏐", "🏉", "🎱", "🏓", "🏸", "🥊", "⛷️", "🏂", "🏋️‍♂️",
  "🚗", "🚕", "🚌", "🚎", "🏎️", "🚓", "🚑", "🚒", "🚛", "🚜", "🚲", "🛴", "🏍️", "🚀", "✈️",
  "✅", "❌", "❗", "❓", "⚠️", "💯", "🚸", "🔞", "🆗", "🆒", "🆕", "🆓", "🔝", "🔜"
]